<template>
  <ul class="sign-list-component" v-if="signList.length">
    <li
      class="sl-component-li"
      v-for="item in signList"
      :key="item.type + item.time"
    >
      <div class="slc-li-left">
        <div class="slcl-left-top">{{ item.type }}</div>
        <div class="slcl-left-bottom">{{ item.time }}</div>
      </div>
      <div :class="`slc-li-right ${item.num < 0 ? '' : ' slc-li-right--blue'}`">
        {{ item.num }}
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "SignListComponent",
  props: {
    signList: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-list-component {
  margin-top: 0.1rem;
  padding: 0 0.15rem;

  .sl-component-li:first-child {
    border-top: 1px solid #f2f2f2;
  }
  .sl-component-li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.075rem 0;
    border-bottom: 1px solid #f2f2f2;
  }
  .slcl-left-top {
    font-size: 0.14rem;
    color: #333333;
  }

  .slcl-left-bottom {
    margin-top: 0.05rem;
    font-size: 0.12rem;
    color: #999999;
  }

  .slc-li-right {
    font-size: 0.16rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    font-family: Helvetica-Bold, Helvetica;
  }

  .slc-li-right--blue {
    color: #53a9ff;
  }

  .slc-li-right-green {
    color: #29b18b;
  }
}
</style>

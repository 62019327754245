<template>
  <div class="sign-container">
    <mescroll-vue
      ref="mescroll"
      @init="mescrollInit"
      :up="state.mescrollUp"
      :down="state.mescrollDown"
      id="sign-container-mescroll"
    >
      <div class="sign-container-header">
        <div class="sign-header-left">
          <span class="sh-left-top">我的云贝</span>
          <span class="sh-left-bottom">
            <span class="shl-bottom-num">{{ state.user_yunbei }}</span>
            <span class="shl-bottom-unit">个</span>
          </span>
        </div>
        <img
          alt="cloud_shell.png"
          class="sign-header-right"
          src="./../assets/cloud_shell.png"
        />
      </div>
      <van-tabs
        color="#53A9FF"
        v-model:active="active"
        class="sign-container-tab"
        title-active-color="#53A9FF"
        title-inactive-color="rgba(0, 0, 0, 0.6)"
        @change="cloudTabs"
      >
        <van-tab title="全部明细" class="sign-tab-all">
          <SignListComponent :signList="state.signList" />
        </van-tab>
        <van-tab title="收入明细" class="sign-tab-income">
          <SignListComponent :signList="state.signList" />
        </van-tab>
        <van-tab title="支出明细" class="sign-tab-disburse">
          <SignListComponent :signList="state.signList" />
        </van-tab>
      </van-tabs>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue";
import { reactive, getCurrentInstance, onMounted, ref } from "vue";

import personalApi from "@/api/personal";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
import SignListComponent from "@/components/lists/SignListComponent.vue";

export default {
  name: "SignList",
  components: {
    MescrollVue,
    SignListComponent,
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const downCallback = (mescroll) => {
      state.num = 1;
      getListData();
    };
    const upCallback = (mescroll) => {
      state.num += 1;
      getListData(true);
    };

    const state = reactive({
      num: 1,
      size: 15,
      signList: [],
      user_yunbei: 0,
      mescroll: null,
      mescrollDown: {
        auto: false,
        callback: downCallback,
      },
      mescrollUp: {
        // use: true,
        auto: false,
        callback: upCallback,
        empty: {
          warpId: "sign-container-mescroll",
          icon: require("../assets/yun_bei_nodata.png"),
          tip: "暂无云贝记录",
        },
        htmlNodata: '<p class="upwarp-nodata">-- 已经到最底部啦 --</p>',
        htmlLoading:
          '<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">加载中...</p>',
        noMoreSize: 5,
        isBounce: true,
        page: {
          num: 0,
          size: 15,
        },
        // toTop: {
        //   src: require("../assets/topButton.png"),
        //   offset: 500,
        // },
      },
    });

    const active = ref(0);
    const { proxy } = getCurrentInstance();

    const mescrollInit = (mescroll) => {
      state.mescroll = mescroll;
    };

    const cloudTabs = () => {
      state.num = 1;
      getListData();
    };

    // 获取云贝
    const getYunbei = async () => {
      const res = await proxy.$request.handleFetchGet(personalApi.userindex);
      state.user_yunbei = res.user_yunbei;
    };

    // 获取云贝列表
    const getListData = async (status) => {
      let type =
        active.value === 0
          ? ""
          : active.value === 1
          ? `&s=income`
          : `&s=expenditure`;
      proxy.$request
        .handleFetchGet(
          `${personalApi.yb_record}${state.num}?ps=${state.size}${type}`
        )
        .then((res) => {
          state.mescroll.removeEmpty();
          if (res.code === 2000) {
            if (status) {
              if (res.total && res.total > state.size) {
                state.signList.push(...res.yb_list);
                state.mescroll.endSuccess(
                  res.yb_list,
                  state.signList.length < res.total
                );
              } else {
                state.signList = res.yb_list;
                state.mescroll.endSuccess(
                  res.yb_list,
                  state.signList.length < res.total
                );
              }
            } else {
              state.signList = res.yb_list;
              state.mescroll.endSuccess(res.yb_list);
              if (!res.total) {
                state.mescroll.hideUpScroll(true);
              }
            }
          }
          if (!res.total) {
            state.mescroll.showEmpty();
          }
        })
        .catch((err) => {
          state.mescroll.endErr();
        });
    };

    onMounted(() => {
      getYunbei();
      getListData();
    });

    return {
      state,
      active,
      cloudTabs,
      mescrollInit,
    };
  },
};
</script>
